* {
    margin: 0;
    padding: 0;
}

/* not logged in */
.login-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #201b2c;
    color: #fefefe;
}

.login-button {
    border-radius: 13px;
    width: 190px;
    height: 50px;
    border: 1px solid #fefefe;
    background-color: transparent;
    color: #fefefe;
    font-size: 17px;
    
    cursor: pointer;
}

.login-button:hover {
    background-color: rgba(255, 255, 255, .1);
}

.page-container {
    display: flex;
    background-color: #1d1f2b;
}

.sidebar {
    width: 300px;
    margin: 30px;

    display: flex;
    flex-direction: column;
}

.sidebar > hr {
    margin: 0 30px 0 30px;
}

.sidebar > p {
    font-size: 20px;
    color: #fefefe;
    margin: 0 30px 0 30px;
}

/* logged in */
.dashboard-container {
    padding: 30px;
    height: 100vh;
    width: 100%;
    color: #fefefe;
}

.welcome-container {
    display: flex;
    justify-content: center;
    gap: 20px;
}

.management-container {
    display: flex;
    justify-content: center;
}

.button-logout {
    border: 1px solid #fefefe;
    border-radius: 13px;
    width: 90px;
    font-size: 17px;
    color: #fefefe;
    background-color: transparent;

    cursor: pointer;
}

.button-logout:hover {
    background-color: rgba(255, 255, 255, .1);
}

.sidebar > div {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.show-backups {
    margin: 40px;
    padding: 40px;
}
