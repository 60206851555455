.empresa-container {
    background-color: #1d1f2b;
    display: flex;
    width: 100%;
    height: 100vh;
}

.search-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}
