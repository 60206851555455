@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');

::-webkit-scrollbar { width: 8px; height: 3px; }
::-webkit-scrollbar-button { background-color: #666; }
::-webkit-scrollbar-track { background-color: #646464; }
::-webkit-scrollbar-track-piece { background-color: #000; }
::-webkit-scrollbar-thumb { height: 50px; background-color: #666; }
::-webkit-scrollbar-corner { background-color: #646464; }
::-webkit-resizer { background-color: #666; }

body {
    margin: 0;
    font-family: 'Roboto', sans-serif;
}

body * {
    box-sizing: border-box;
}

.button-icon:hover {
    cursor: pointer;
    filter: brightness(.8);
}
