.panel {
    border-radius: 30px;
}

.back {
    background-color: #252837;
}

.front {
    background-color: #303346;
}
