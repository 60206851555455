.empresa-item {
    display: flex;
    justify-content: space-between;
    
    color: #fefefe;
    font-size: 23px;

    padding: 20px;

    border-radius: 8px;

    transition: .2;

}

.empresa-item:hover {
    background-color: rgba(255, 255, 255, 0.1);
}

.blocked-icon {
    color: tomato;
}
