.panel-container {
    height: 100%;
    width: 100%;
    padding: 10px;
    overflow: scroll;
    overflow-x: hidden;
    border-radius: 7px;
}

.search-container {
    display: flex;
    background-color: transparent;
    flex-direction: column;
    /* justify-content: flex-start; */
    align-items: flex-start;
    gap: 30px;
    padding: 10px 20px 10px 0px;
    border-radius: 7px;
}

.cadastrar-button {
    border: none;
    border-radius: 7px;
    width: fit-content;
    padding: 10px 15px 10px 15px;
    background-color: #1d1f2b;
    color: #fefefe;
    font-size: 20px;

    box-sizing: border-box;

    white-space: nowrap;
}

.cadastrar-button:hover {
    cursor: pointer;
}

.search-input {
    margin: 5px;
    border: none;
    border-radius: 7px;
    font-size: 20px;
    /* padding-left: 5px; */
    width: 100%;
    /* overflow:scroll; */
}

.fullwidth {
    width: 100%;
    text-align: left;
}

.empresa-item-container {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin: 30px;
}
