.main-login {
    width: 100vw;
    height: 100vh;
    background: #201b2c;

    display: flex;
    justify-content: center;
    align-items: center;
}

.left-side {
    width: 50vw;
    height: 100vh;

    display: flex;
    justify-content: center;
    align-items: center;

    flex-direction: column;
}

.left-side > h1 {
    font-size: 3vw;
    color: #77ffc0;
}

.left-side > img {
    width: 34vw;
}

.right-side {
    width: 50vw;
    height: 100vh;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.card-login {
    width: 60%;
    height: 45%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: 30px 35px;
    background: #2f2841;
    border-radius: 20px;

    box-shadow: 0px 10px 40px #00000056;

}

.card-login > h1 {
    color: #00ff88;
    font-weight: 800;
    margin: 0;
}

.textfield {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin: 10px 0px;
}

.textfield > input {
    width: 100%;
    border: none;
    border-radius: 10px;
    padding: 15px;

    background: #514869;
    color: #f0ffffde;
    font-size: 12pt;
    box-shadow: 0px 10px 40px #00000056;
    outline: none;
    box-sizing: border-box;
}

.textfield > label {
    color: #f0ffffde;
    margin-bottom: 10px;
}

.textfield > input::placeholder {
    color: #f0ffff94;
}

.btn-login {
    margin-top: 20px;
    width: 100%;
    padding: 16px 0px;
    border: none;
    border-radius: 8px;
    outline: none;
    text-transform: uppercase;
    font-weight: 800;
    letter-spacing: 3px;
    color: #2b134b;
    background: #00ff88;
    cursor: pointer;
    box-shadow: 0px 10px 40px -10px #00ff8052;
}

@media only screen and (max-width: 950px) {
    .card-login {
        width: 85%;
    }
}

@media only screen and (max-width: 600px) {
    .main-login {
        justify-content: flex-start;
        gap: 50px;
        flex-direction: column;
    }

    .left-side > h1 {
        display: none;
    }

    
    .left-side {
        width: 100%;
        height: auto;
    }
    
    .right-side {
        width: 100%;
        height: auto;
    }

    .left-login-image {
        width: 50vw;
    }

    .card-login {
        width: 90%;
    }
}
