.sidebar {
    width: 300px;
    /* margin: 30px; */

    display: flex;
    flex-direction: column;
}

.sidebar > hr {
    margin: 0 30px 0 30px;
}

.sidebar > p {
    font-size: 20px;
    color: #fefefe;
    margin: 0 30px 0 30px;
}

.sidebar > ul {
    font-size: 20px;
    color: #fefefe;
    margin: 0 30px 0 30px;
}

.sidebar > b {
    font-size: 20px;
    color: #fefefe;
    margin: 0 30px 0 30px;
}

.sidebar > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
}

.version-container {
    color: #fefefe;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-bottom: 10px;
    height: 100%;
}

.button-add-empresa {
    border: 1px solid #fefefe;
    border-radius: 13px;
    width: 50%;
    background-color: transparent;
    color: #fefefe;
    font-size: 17px;
    padding: 10px;

    transition: 0.1s;
}

.button-add-empresa:hover {
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.1);
}
