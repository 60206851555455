.dashboard-container {
    background-color: #1d1f2b;
    display: flex;
    width: 100%;
    height: 150vh;
}

.info-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 20px;
    padding-left: 10px;
    padding-right: 10px;
}
