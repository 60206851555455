.server-performance-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding: 10px 0 10px 0;
}

.fa {
    font-size: 50px;
}

.performance-button {
    border: none;
    border-radius: 7px;
    width: fit-content;
    padding: 10px 15px 10px 15px;
    background-color: #1d1f2b;
    color: #fefefe;
    font-size: 20px;

    box-sizing: border-box;

    white-space: nowrap;
}

.performance-button:hover {
    cursor: pointer;
}

.performance-button:disabled {
    filter: brightness(.5);
    cursor: default;
}
