.server-config-input {
    margin: 5px;
    border: none;
    border-radius: 7px;
    font-size: 20px;
    padding-left: 5px;
    width: 80%;
    overflow:scroll;
}

.server-config-button {
    border: none;
    border-radius: 7px;
    width: fit-content;
    padding: 10px 15px 10px 15px;
    background-color: #1d1f2b;
    color: #fefefe;
    font-size: 20px;

    box-sizing: border-box;

    white-space: nowrap;
}

.server-config-button:hover {
    cursor: pointer;
}
