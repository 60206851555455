.backup-item-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    border-radius: 13px;
    border: 1px solid #fefefe;
    width: 100%;
    padding: 10px 40px 10px 40px;
    font-size: 20px;
    transition: 0.1s;
}

.backup-item-container:hover {
    background-color: rgba(255, 255, 255, 0.1);
}
