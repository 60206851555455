.toast-button {
    border: 1px solid black;
    border-radius: 13px;
    background: transparent;
    padding: 5px 20px 5px 20px;

    cursor: pointer;
}

.toast-button:hover {
    background-color: rgba(0, 0, 0, .1);
}
